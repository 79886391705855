<template>
  <inner-link
    v-for="(item, index) in tagsViewStore.iframeViews"
    :key="item.path"
    :iframe-id="'iframe' + index"
    v-show="route.path === item.path"
    :src="iframeUrl(item.meta.link, item.query)"
  />
</template>

<script setup>
import InnerLink from '../InnerLink/index'
import useTagsViewStore from '@/store/modules/tagsView'

const route = useRoute()
const tagsViewStore = useTagsViewStore()

function iframeUrl(url, query) {
  if (Object.keys(query).length > 0) {
    let params = Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&')
    return url + '?' + params
  }
  return url
}
</script>
