import Resp from '../resp.json'
import Data from './data.json'
export default [] || [
  {
    url: /\/texas\/table\/\d/,
    method: 'get',
    response: () => {
      return Data.getInfo
    }
  },
  {
    url: /\/texas\/table\/add/,
    method: 'post',
    response: () => {
      return Resp.success
    }
  },
  {
    url: /\/texas\/table\/edit/,
    method: 'put',
    response: () => {
      return Resp.success
    }
  },
  {
    url: /\/texas\/table\/del\/\d/,
    method: 'delete',
    response: () => {
      return Resp.success
    }
  },
  {
    url: /\/texas\/table/,
    method: 'get',
    response: () => {
      return Data.getList
    }
  }
]
