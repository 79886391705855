/**
 * 枚举类
 */

import { TagProps } from 'element-plus'
import { enumOptionSelector } from './index'

// ========== COMMON 模块 ==========
// 全局通用状态枚举
export const CommonStatusEnum = {
  ENABLE: 1, // 开启
  DISABLE: 0 // 禁用
}

// 全局通用状态枚举
export enum ComStatusEnum {
  是 = 1, // 禁用
  否 = 0 // 开启
}

export enum Status {
  停用 = 0,
  启用
}
export const StatusOptions = enumOptionSelector(Status)

export const ComStatusEnumTxt = {
  '0': '否',
  '1': '是'
}

export const CommonStatusOptions = enumOptionSelector(ComStatusEnum)

// ========== SYSTEM 模块 ==========
/**
 * 菜单的类型枚举
 */
export const SystemMenuTypeEnum = {
  DIR: 1, // 目录
  MENU: 2, // 菜单
  BUTTON: 3 // 按钮
}

/**
 * 数据权限的范围枚举
 */
export const SystemDataScopeEnum = {
  ALL: 1, // 全部数据权限
  DEPT_CUSTOM: 2, // 指定部门数据权限
  DEPT_ONLY: 3, // 部门数据权限
  DEPT_AND_CHILD: 4, // 部门及以下数据权限
  DEPT_SELF: 5 // 仅本人数据权限
}

/**
 * 操作弹窗类型
 */
export enum OptionType {
  CREATE = 'create',
  UPDATE = 'update',
  CHECK = 'check',
  CLONE = 'clone',
  DELETE = 'del'
}

/**
 * 操作弹窗类型
 */
export enum OptionTxtType {
  'create' = '新增',
  'update' = '编辑',
  'check' = '查看',
  'clone' = '克隆',
  'del' = '删除'
}

export const MO_SHENG_REN_ID = '20'
export const DEFAULT_TAG_NAME: TagProps['type'][] = [
  'info',
  'primary',
  'success',
  'warning',
  'danger'
]
