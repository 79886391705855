import { createApp } from 'vue'

import Cookies from 'js-cookie'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'

import App from './App.vue'
import '@/assets/styles/index.scss' // global css

import uploader from 'vue-simple-uploader'
import 'vue-simple-uploader/dist/style.css'

import store from '@/store'
import router from '@/router'
import directive from '@/directive' // directive

// 注册指令
import plugins from '@/plugins' // plugins
import { download } from '@/utils/request'

// svg图标
import '@/plugins/svgIcon'
import SvgIcon from '@/components/SvgIcon/index.vue'
import elementIcons from '@/components/SvgIcon/svgicon'

import '@/permission' // permission control

import { useDict } from '@/utils/dict'
import {
  parseTime,
  resetForm,
  addDateRange,
  handleTree,
  selectDictLabel,
  selectDictLabels
} from '@/utils/parse'

// 分页组件
import Pagination from '@/components/Pagination/index.vue'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar/index.vue'
// 富文本组件
import Editor from '@/components/Editor/index.vue'
// 文件上传组件
import FileUpload from '@/components/FileUpload/index.vue'
// 图片上传组件
import ImageUpload from '@/components/ImageUpload/index.vue'
// 图片预览组件
import ImagePreview from '@/components/ImagePreview/index.vue'
// 自定义树选择组件
import TreeSelect from '@/components/TreeSelect/index.vue'
// 字典标签组件
import DictTag from '@/components/DictTag/index.vue'
// 字典标签组件
import { Form } from '@/components/Form/index'
// 字典标签组件
import { Table } from '@/components/Table/index'
// 字典标签组件
import { Search } from '@/components/Search/index'
// icon组件
import { Icon } from '@/components/Icon/index'
// Dialog组件
import { Dialog } from '@/components/Lib'
import useVipLevel from '@/store/modules/vip'

const app = createApp(App)

// 全局方法挂载
app.config.globalProperties.useDict = useDict
app.config.globalProperties.download = download
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.selectDictLabels = selectDictLabels

/**
 * @params
 * err：错误对象
 * instance：触发该错误的组件实例
 * info：一个指出错误来源类型信息的字符串
 *
 * 特点:
 * 组件渲染器
 * 事件处理器
 * 生命周期钩子
 * setup() 函数
 * 侦听器
 * 自定义指令钩子
 * 过渡 (Transition) 钩子
 */
app.config.errorHandler = (err: any, instance: any, info: any) => {
  console.warn(err, instance, info)
}

// 全局组件挂载
app.component('Form', Form)
app.component('Table', Table)
app.component('Search', Search)
app.component('DictTag', DictTag)
app.component('Pagination', Pagination)
app.component('TreeSelect', TreeSelect)
app.component('FileUpload', FileUpload)
app.component('ImageUpload', ImageUpload)
app.component('ImagePreview', ImagePreview)
app.component('RightToolbar', RightToolbar)
app.component('Editor', Editor)
app.component('Icon', Icon)
app.component('Dialog', Dialog)

app.use(router)
app.use(store)
app.use(plugins)
app.use(elementIcons)
app.use(uploader)
app.component('svg-icon', SvgIcon)

directive(app)

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: Cookies.get('size') || 'default'
})

app.mount('#app')
