import request from '@/api/core'
import { FilePageReqVO, FilePresignedUrlRespVO } from '@/components/UploadFile/src/types'

// 查询文件列表
export const getFilePage = (params: FilePageReqVO) => {
  return request({ url: '/infra/file/page', params, method: 'get' })
}

// 删除文件
export const deleteFile = (id: number) => {
  return request({ url: '/infra/file/delete?id=' + id, method: 'delete' })
}

// 获取文件预签名地址
export const getFilePresignedUrl = (path: string) => {
  return request<FilePresignedUrlRespVO>({
    url: '/infra/file/presigned-url',
    method: 'get',
    params: { path }
  })
}

// 创建文件
export const createFile = (data: any) => {
  return request({ url: '/infra/file/create', method: 'post', data })
}
