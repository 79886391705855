<template>
  <div :class="`upload-box ${imgType === 'poster' ? 'poster-box' : ''}`">
    <el-upload
      :id="uuid"
      :accept="fileType.join(',')"
      :action="uploadUrl"
      :before-upload="beforeUpload"
      :class="['upload', drag ? 'no-border' : '']"
      :drag="drag"
      :multiple="false"
      :on-error="uploadError"
      :on-success="uploadSuccess"
      :on-progress="handleProcess"
      :show-file-list="false"
      :http-request="httpRequest"
    >
      <div class="upload-loading-wrap">
        <template v-if="modelValue">
          <img :src="urlHeader + modelValue" class="upload-image" />
          <div class="upload-handle" @click.stop>
            <!-- <div class="handle-icon" @click="editImg" v-if="!disabled">
              <Icon icon="ep:edit" />
              <span v-if="showBtnText">编辑</span>
            </div> -->
            <div class="handle-icon" @click="imagePreview(modelValue)">
              <Icon :size="20" icon="ep:zoom-in" />
              <span v-if="showBtnText">预览</span>
            </div>
            <div v-if="showDelete && !disabled" class="handle-icon" @click="deleteImg">
              <Icon :size="20" icon="ep:delete" />
              <span v-if="showBtnText">删除</span>
            </div>
          </div>
        </template>
        <template v-else>
          <el-progress
            v-if="showPercent"
            type="circle"
            :percentage="uploadPercent"
            :style="imgType === 'poster' ? '' : 'margin-top: 7px'"
          />
          <div v-else class="upload-empty">
            <slot name="empty">
              <Icon :size="20" icon="ep:plus" />
            </slot>
          </div>
        </template>
      </div>
    </el-upload>
    <div class="el-upload__tip">
      <slot name="tip"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { UploadProps, UploadFile } from 'element-plus'

import { generateUUID } from '@/utils'
import { propTypes } from '@/utils/propTypes'
import { createImageViewer } from '@/components/ImageViewer'
import { useUpload } from './useUpload'

defineOptions({ name: 'UploadImg' })

type FileTypes =
  | 'image/apng'
  | 'image/bmp'
  | 'image/gif'
  | 'image/jpeg'
  | 'image/pjpeg'
  | 'image/png'
  | 'image/svg+xml'
  | 'image/tiff'
  | 'image/webp'
  | 'image/x-icon'

const urlHeader = import.meta.env.VITE_ASSERT_DOMAIN

// 接受父组件参数
const props = defineProps({
  modelValue: propTypes.string.def(''),
  imgType: propTypes.string.def('regular'), // 图片类型 ==> 非必传（默认为 regular,poster为海报格式）
  drag: propTypes.bool.def(true), // 是否支持拖拽上传 ==> 非必传（默认为 true）
  disabled: propTypes.bool.def(false), // 是否禁用上传组件 ==> 非必传（默认为 false）
  fileSize: propTypes.number.def(2), // 图片大小限制 ==> 非必传（默认为 10M）
  fileType: propTypes.array.def([
    'image/jpeg',
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/gif'
  ]), // 图片类型限制 ==> 非必传（默认为 ["image/jpeg", "image/png", "image/gif"]）
  height: propTypes.string.def('150px'), // 组件高度 ==> 非必传（默认为 150px）
  width: propTypes.string.def('150px'), // 组件宽度 ==> 非必传（默认为 150px）
  borderradius: propTypes.string.def('8px'), // 组件边框圆角 ==> 非必传（默认为 8px）
  // 是否显示删除按钮
  showDelete: propTypes.bool.def(true),
  // 是否显示按钮文字
  showBtnText: propTypes.bool.def(true)
})
const message = useMessage() // 消息弹窗
// 生成组件唯一id
const uuid = ref('id-' + generateUUID())
// 生成组件唯一id
// const loading = ref(false)
const uploadPercent = ref<number>(0)
const showPercent = ref<boolean>(false)
// 查看图片
const imagePreview = (imgUrl: string) => {
  createImageViewer({
    zIndex: 9999999,
    urlList: [urlHeader + imgUrl]
  })
}

const emit = defineEmits(['update:modelValue'])

const deleteImg = () => {
  emit('update:modelValue', '')
}

const { uploadUrl, httpRequest } = useUpload()

// const editImg = () => {
//   const dom = document.querySelector(`#${uuid.value} .el-upload__input`)
//   dom && dom.dispatchEvent(new MouseEvent('click'))
// }

const beforeUpload: UploadProps['beforeUpload'] = (rawFile) => {
  const imgSize = rawFile.size / 1024 / 1024 < props.fileSize
  const imgType = props.fileType
  if (!imgType.includes(rawFile.type as FileTypes))
    message.notifyWarning('上传图片不符合所需的格式！')
  if (!imgSize) message.notifyWarning(`上传图片大小不能超过 ${props.fileSize}M！`)
  // loading.value = true
  const res = imgType.includes(rawFile.type as FileTypes) && imgSize
  if (res) {
    showPercent.value = true
  }
  return res
}

//进度条
const handleProcess = (_event, file) => {
  console.log(file.percentage)

  uploadPercent.value = file.percentage.toFixed(0) * 1
}

// 图片上传成功提示
const uploadSuccess: UploadProps['onSuccess'] = (res: any, file: UploadFile): void => {
  console.log(res, file)
  if (!file.percentage || file.percentage < 100) return

  // loading.value = false
  showPercent.value = false
  if (res.code === 200) {
    message.success('上传成功')
    emit('update:modelValue', '/' + res.url.replace(/http(s)?\:\/\/[\w|\.]+\//, ''))
  } else {
    message.notifyError(res.msg)
  }
}

// 图片上传错误提示
const uploadError = () => {
  // loading.value = false
  showPercent.value = false
  message.notifyError('图片上传失败，请您重新上传！')
}
</script>
<style lang="scss" scoped>
.is-error {
  .upload {
    :deep(.el-upload),
    :deep(.el-upload-dragger) {
      border: 1px dashed var(--el-color-danger) !important;

      &:hover {
        border-color: var(--el-color-primary) !important;
      }
    }
  }
}

:deep(.disabled) {
  .el-upload,
  .el-upload-dragger {
    cursor: not-allowed !important;
    background: var(--el-disabled-bg-color);
    border: 1px dashed var(--el-border-color-darker) !important;

    &:hover {
      border: 1px dashed var(--el-border-color-darker) !important;
    }
  }
}

.upload-box {
  .no-border {
    :deep(.el-upload) {
      border: none !important;
    }
  }

  :deep(.upload) {
    .el-upload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: v-bind(width);
      height: v-bind(height);
      overflow: hidden;
      border: 1px dashed var(--el-border-color-darker);
      border-radius: v-bind(borderradius);
      transition: var(--el-transition-duration-fast);

      &:hover {
        border-color: var(--el-color-primary);

        .upload-handle {
          opacity: 1;
        }
      }

      .el-upload-dragger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
        border: 1px dashed var(--el-border-color-darker);
        border-radius: v-bind(borderradius);

        &:hover {
          border: 1px dashed var(--el-color-primary);
        }
      }

      .el-upload-dragger.is-dragover {
        background-color: var(--el-color-primary-light-9);
        border: 2px dashed var(--el-color-primary) !important;
      }

      .upload-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .upload-loading-wrap {
        width: 100%;
        height: 100%;
      }

      .upload-empty {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 30px;
        color: var(--el-color-info);

        .el-icon {
          font-size: 28px;
          color: var(--el-text-color-secondary);
        }
      }

      .upload-handle {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgb(0 0 0 / 60%);
        opacity: 0;
        box-sizing: border-box;
        transition: var(--el-transition-duration-fast);
        align-items: center;
        justify-content: center;

        .handle-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 6%;
          color: aliceblue;

          .el-icon {
            margin-bottom: 40%;
            font-size: 130%;
            line-height: 130%;
          }

          span {
            font-size: 85%;
            line-height: 85%;
          }
        }
      }
    }
  }

  .el-upload__tip {
    line-height: 18px;
    color: var(--el-text-color-secondary);

    /* text-align: center; */
  }
}

.poster-box {
  :deep(.upload) {
    .el-upload {
      height: auto;
      .el-progress {
        width: 100%;
      }
      .el-progress-circle {
        width: calc(100% * 9 / 16) !important;
        height: 100% !important;
        margin: 0 auto;
      }
    }
    .el-upload {
      &-dragger::after {
        content: '';
        display: block;
        margin-top: calc(100% * 9 / 16); //margin 百分比相对父元素宽度计算
      }
    }
  }
  .el-upload__tip {
    display: none;
  }
}
</style>
