import request from '@/utils/request'
import { AxiosPromise } from 'axios'

// 获取路由
export const getRouters = () => {
  return request({
    url: '/getRouters',
    method: 'get'
  }) as AxiosPromise<RespRouteRecordRaw>
}
