import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 获取token
export const getAccessToken = () => {
  // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
  return Cookies.get(AccessTokenKey) ? Cookies.get(AccessTokenKey) : Cookies.get('ACCESS_TOKEN')
}
// 刷新token
export const getRefreshToken = () => {
  return Cookies.get(RefreshTokenKey)
}

// 设置token
export const setTokens = (token: any) => {
  Cookies.set(RefreshTokenKey, token.refreshToken)
  Cookies.set(AccessTokenKey, token.accessToken)
}

// 删除token
export const removeTokens = () => {
  Cookies.delete(AccessTokenKey)
  Cookies.delete(RefreshTokenKey)
}

/** 格式化token（jwt格式） */
export const formatToken = (token: string): string => {
  return 'Bearer ' + token
}
// ========== 账号相关 ==========

export type LoginFormType = {
  tenantName: string
  username: string
  password: string
  rememberMe: boolean
}

// ========== 租户相关 ==========

const TenantIdKey = 'TENANT_ID'
const TenantNameKey = 'TENANT_NAME'

export const getTenantName = () => {
  return Cookies.get(TenantNameKey)
}

export const setTenantName = (username: string) => {
  Cookies.set(TenantNameKey, username, { exp: 30 * 24 * 60 * 60 })
}

export const removeTenantName = () => {
  Cookies.delete(TenantNameKey)
}

export const getTenantId = () => {
  return Cookies.get(TenantIdKey)
}

export const setTenantId = (username: string) => {
  Cookies.set(TenantIdKey, username)
}

export const removeTenantId = () => {
  Cookies.delete(TenantIdKey)
}
