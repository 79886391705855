<script lang="tsx">
import router from '@/router'
import { OptionType } from '@/utils/constants'
import { ElSteps, ElStep, ElRow, ElButton, ElDivider } from 'element-plus'
import { CSSProperties } from 'vue'

enum StepStatus {
  'CANCEL',
  'PRE',
  'NEXT'
}

export interface StepProp {
  title: string
  key: string
  icon: ReturnType<typeof defineComponent>
}

export default defineComponent({
  name: 'CustomStep',
  props: {
    initStep: {
      type: Number,
      default: 0
    },
    stepList: {
      required: true,
      type: Array as PropType<StepProp[]>,
      default: () => []
    },
    validators: {
      type: Array as PropType<(() => Promise<any>)[]>,
      default: () => []
    },
    buttonStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => ({
        margin: '0 0 0 90px'
      })
    },
    divider: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    },
    optionType: {
      type: String as PropType<OptionType>,
      default: OptionType.CREATE
    }
  },
  emits: ['change', 'submit'],
  setup(
    { divider, center, buttonStyle, initStep, stepList, validators },
    { attrs, slots, emit, expose }
  ) {
    const activedRef = ref(0)
    // const showStepSave = computed(() => [OptionType.CLONE, OptionType.UPDATE].includes(optionType))

    const preButton = computed(() => activedRef.value > 0)
    const nextButton = computed(() => activedRef.value < stepList.length - 1)

    watch(
      () => initStep,
      () => {
        activedRef.value = initStep
      }
    )

    /**
     * Step按钮触发
     * @param status
     */
    const handleClick = (status: StepStatus = StepStatus.NEXT) => {
      if (status === StepStatus.CANCEL) {
        router.go(-1)
        return
      }
      if (status === StepStatus.PRE) {
        emit('change', activedRef.value, (result: boolean) => {
          if (result) activedRef.value -= 1
        })
        return
      }

      handleSave()
    }

    const handleSave = (hasNext = true) => {
      console.log('step handleSave', validators, activedRef.value)

      validators[activedRef.value]()
        .then((res) => {
          if (!hasNext) {
            emit('submit', res)
            return
          }
          if (activedRef.value === stepList.length - 1) {
            emit('submit', res)
            return
          }
          emit('change', activedRef.value, (result: boolean) => {
            if (result) activedRef.value += 1
          })
        })
        .catch((err) => {
          console.log(stepList[activedRef.value].title + ': validator error', err)
        })
    }

    expose({
      handleClick
    })

    return () => (
      <>
        <ElSteps
          class="custom-step"
          active={activedRef.value}
          finish-status="success"
          simple
          {...attrs}
        >
          {stepList.map((step, index) => {
            return <ElStep key={index} title={step.title} icon={step.icon}></ElStep>
          })}
        </ElSteps>
        <ElRow class="custom-step-body">{slots.default?.()}</ElRow>
        {divider && <ElDivider></ElDivider>}
        <ElRow style={{ ...buttonStyle, justifyContent: center ? 'center' : 'start' }}>
          <ElButton onClick={() => handleClick(StepStatus.CANCEL)}>取消</ElButton>
          {preButton.value && (
            <ElButton type="primary" plain onClick={() => handleClick(StepStatus.PRE)}>
              上一步
            </ElButton>
          )}
          {/* {nextButton.value &&
            ((stepSave && activedRef.value !== stepList.length - 1) ||
              (!stepSave && activedRef.value === stepList.length - 1 && showSave)) && (
              <ElButton type="primary" onClick={() => handleClick(StepStatus.NEXT)}>
                {activedRef.value === stepList.length - 1 ? '保存2' : '下一步'}
              </ElButton>
            )} */}
          {nextButton.value && (
            <ElButton type="primary" plain onClick={() => handleClick(StepStatus.NEXT)}>
              下一步
            </ElButton>
          )}

          {/* {activedRef.value === stepList.length - 1 && ( */}
          <ElButton type="primary" onClick={() => handleSave(false)}>
            保存
          </ElButton>
          {/* )} */}
        </ElRow>
      </>
    )
  }
})
</script>

<style scoped>
.custom-step {
  width: 100%;
  margin-bottom: 20px;
}

.custom-step-body {
  /* height: calc(100vh - 280px);
  overflow-y: auto; */
}

.pagination-container {
  padding: 32px 16px;
  background: #fff;
}

.pagination-container.hidden {
  display: none;
}
</style>
