import Request from '@/utils/request'
import { AxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import qs from 'qs'

interface ICoreRequest extends AxiosRequestConfig {
  isFormat?: boolean
}

const paramsHandler = (params) => {
  if (!isObject(params)) return params
  return Object.keys(params).reduce((prev, next) => {
    return params[next] !== undefined
      ? {
          ...prev,
          [next]: params[next]
        }
      : prev
  }, {})
}

const isObject = (fn) => {
  return Object.prototype.toString.call(fn) === '[object Object]'
}

export default <T>({
  url,
  method,
  params = {},
  data = {},
  headers = {},
  isFormat = true,
  responseType
}: ICoreRequest): Promise<T> => {
  const getParams = paramsHandler(params)
  const hasParams = Object.keys(getParams).length
  return Request({
    method,
    url:
      method?.toLocaleLowerCase() === 'get' && hasParams
        ? url + '?' + qs.stringify(getParams, { indices: false })
        : url,
    data: paramsHandler(data), // post,put,delete 使用data参数
    // params: paramsHandler(params), // get使用params参数
    headers,
    responseType
  })
    .then((value: any): T | false => {
      if (!value) return false

      if (value.responseType === 'blob') return {} as T // 文件流下载

      if (!isFormat) return value as any
      return value.data || value
    })
    .catch(() => {
      // ElMessage.error(typeof error === 'string' ? error : error.message || '服务器异常')
      return false
      // return Promise.reject(false)
    }) as Promise<T>
}
